<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="vehicleData.avatar ? '' : 'primary'"
            :class="vehicleData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="vehicleData.avatar"
              :src="require(`@/assets/images/avatars/${vehicleData.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(resolveModel(vehicleData)) }}</span>
          </v-avatar>

          <span class="mb-2">{{ resolveModel(vehicleData) }} {{ vehicleData.year_model }}</span>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiSpeedometer }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ vehicleData.mileage ? vehicleData.mileage.toLocaleString("en-US") : '' }}
              </h3>
              <span>Mileage</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(jobDoneCount) }}
              </h3>
              <span>Job Done</span>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Make:</span>
              <span class="text--secondary">{{ resolveMake(vehicleData) }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Model:</span>
              <span class="text--secondary">{{ resolveModel(vehicleData) }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Type:</span>
              <span class="text--secondary">{{ resolveType(vehicleData) }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Color:</span>
              <span class="text--secondary">{{ vehicleData.color }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Status:</span>
              <v-chip
                :class="`${resolveUnitCondition(vehicleData.condition)}--text`"
                class="v-chip-light-bg"
                :color="resolveUnitCondition(vehicleData.condition)"
                small
              >
                {{ vehicleData.condition }}
              </v-chip>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Department:</span>
              {{ vehicleData.department_name }}
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Engine No:</span>
              <span class="text--secondary">{{ vehicleData.engine_number }}</span>
            </v-list-item>

            <v-list-item
              v-if="vehicleData.driver"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Driver:</span>
              <span class="text--secondary">{{ vehicleData.driver.full_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Plate No:</span>
              <span class="text--secondary">{{ vehicleData.plate_number }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            v-if="$store.getters['auth/can'](['edit vehicles'])"
            color="primary"
            class="me-3"
            :to="{ name: 'vehicles-edit', params: { id: vehicleData.id } }"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSpeedometer, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api'
import axios from '@axios'
import useUIResolver from '../useUIResolver'

export default {
  props: {
    vehicleData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isDataDialogOpen = ref(false)

    const resolveType = data => data?.vehicle_type?.type
    const resolveModel = data => data?.make_model?.name
    const resolveMake = data => data?.make?.name
    const { resolveUnitCondition } = useUIResolver()
    const jobDoneCount = ref(0)

    onMounted(() => {
      setTimeout(() => {
        // get job done count
        axios.get(`/vehicles/job-done-count/${props.vehicleData.id}`).then(response => {
          jobDoneCount.value = response.data
        })
      }, 500)
    })

    return {
      avatarText,
      kFormatter,
      resolveType,
      resolveModel,
      resolveMake,
      resolveUnitCondition,
      jobDoneCount,

      isDataDialogOpen,
      icons: {
        mdiSpeedometer,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
