<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <vehicle-data-panel :vehicle-data="vehicle"></vehicle-data-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="vehicleTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" v-model="vehicleTab" class="mt-5 pa-1">
          <v-tab-item>
            <job-orders :vehicle="+$router.currentRoute.params.id"></job-orders>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title>Notes</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea
                      v-model="form.description"
                      :error-messages="errors.description"
                      label="Enter todo"
                      outlined
                      dense
                      rows="3"
                      clearable
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.todo_date"
                          :error-messages="errors.todo_date"
                          label="Date"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          clearable
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.todo_date"
                        color="primary"
                        @input="datePicker = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn color="primary" block @click="saveNote"> Save Note </v-btn>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn color="primary" outlined block @click="clearNote"> Clear </v-btn>
                  </v-col>
                </v-row>

                <v-list flat subheader three-line>
                  <v-subheader>
                    <v-switch v-model="switchCompleted" label="Show Completed" @change="showCompleted"> </v-switch>
                  </v-subheader>

                  <v-spacer></v-spacer>

                  <v-list-item-group v-if="notes.length > 0" v-model="todos" multiple>
                    <v-list-item v-for="(note, key) in notes" :key="key">
                      <template #default="{ active }">
                        <v-list-item-action>
                          <v-checkbox v-model="note.completed" @change="changeChecked(note)"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-subtitle>
                            {{ note.description }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <small>{{ note.todo_date }}</small>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action @click="editNote(note)">
                          <v-icon>
                            {{ icons.mdiPencilOutline }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-action @click="deleteNote(note)">
                          <v-icon>
                            {{ icons.mdiDeleteOutline }}
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item v-else>
                    <v-list-item-content>
                      <p class="text-center">No notes available.</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCar, mdiFormatListChecks, mdiCalendarCheckOutline, mdiDeleteOutline, mdiPencilOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import axios from '@axios'
import VehicleDataPanel from './VehicleDataPanel.vue'
import useVehicles from './useVehicles'
import router from '@/router'
import JobOrders from '../job-orders/Index.vue'

const formInit = () => ({
  description: null,
  todo_date: null,
  vehicle_id: null,
  id: null,
})
export default {
  components: {
    VehicleDataPanel,
    JobOrders,
  },
  setup() {
    const todos = ref([])

    const form = ref(formInit())

    const notes = ref([])

    const errors = ref([])

    const datePicker = ref(false)

    const switchCompleted = ref(false)

    const { vehicle, getVehicle } = useVehicles()
    const vehicleTab = ref(null)
    const tabs = [
      { icon: mdiCar, title: 'Overview' },
      { icon: mdiFormatListChecks, title: 'Notes' },
    ]

    const getNotes = () => {
      axios.get(`/todos?vehicle_id=${vehicle.value.id}&include_completed=${switchCompleted.value}`).then(r => {
        notes.value = r.data

        errors.value = []
      })
    }

    const clearNote = () => {
      form.value = formInit()
      form.value.vehicle_id = router.currentRoute.params.id
    }

    const saveNote = () => {
      if (form.value.id) {
        axios
          .put(`/todos/${form.value.id}`, form.value)
          .then(() => {
            clearNote()
            getNotes()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
          })
      } else {
        axios
          .post('/todos', form.value)
          .then(() => {
            clearNote()
            getNotes()
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
          })
      }
    }

    const editNote = note => {
      form.value = note
    }

    const deleteNote = note => {
      axios.delete(`/todos/${note.id}`).then(() => {
        getNotes()
      })
    }

    const showCompleted = () => {
      getNotes()
    }

    const changeChecked = note => {
      form.value = note
      axios.put(`/todos/${note.id}`, form.value).then(() => {
        getNotes()
      })
    }

    if (router.currentRoute.params.id) {
      form.value.vehicle_id = router.currentRoute.params.id

      // fetch vehicle from the server
      getVehicle(router.currentRoute.params.id).then(() => {
        getNotes()
      })
    }

    return {
      // useVehicles
      vehicle,
      getVehicle,

      tabs,
      vehicleTab,
      saveNote,
      editNote,
      deleteNote,
      clearNote,
      getNotes,
      notes,

      errors,

      changeChecked,
      showCompleted,

      todos,
      form,
      datePicker,
      switchCompleted,
      icons: {
        mdiCalendarCheckOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
